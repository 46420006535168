// src/data.js
import { PaperAirplaneIcon, CodeIcon, DeviceMobileIcon, ChipIcon, DatabaseIcon, CubeIcon, AnnotationIcon, AcademicCapIcon } from "@heroicons/react/solid";

export const projects = [
  {
    title: "Safetrap",
    subtitle: "Trap Monitoring System for Hunting and Nature Conservation",
    description:
      "A low-power, long-duration system utilizing an AVR microcontroller with GSM capabilities. Includes a React Web App interfacing with a Django REST API for remote management and monitoring. Learn more at www.safetrap.de.",
    image: "/fallenmelder_3.jpg",
    link: "",
  },
  {
    title: "Virtual Estate",
    subtitle: "Job Booking System for Real Estate Management",
    description:
      "Developed a web portal using a React app and Python Django REST API. Integrated hardware includes an ESP32-S3 with a QR scanner, programmed in C++.",
    image: "/ve.PNG",
    link: "",
  },
  {
    title: "Sleek",
    subtitle: "Bluetooth MIDI Controller",
    description:
      "A BLE MIDI controller integrated into a guitar pickguard, using an NRF52 microcontroller. Developed as a patented solution and award-winning prototype in 2016.",
    image: "/showcase.png",
    link: "",
  },
  {
    title: "Nordex",
    subtitle: "Apprenticeship and Studies as a Mechatronics Engineer",
    description:
      "Gained practical experience in the wind energy industry, working on large machinery in Rostock from 2013 to 2019.",
    image: "/nordex.jpg",
    link: "",
  },
  {
    title: "AgBRAIN & CCI",
    subtitle: "Data Management Systems in Agricultural Engineering",
    description:
      "Full-stack web developer for agricultural data management systems. Developed a Vue PWA with a C# .NET API and a PostgreSQL database. Project completed in 2022.",
    image: "/cci.jpg",
    link: "",
  },
  {
    title: "Kesseböhmer",
    subtitle: "Product Developer",
    description:
      "Involved in the development of electronic products, from circuit design and enclosure modeling to certification processes, spanning from 2023 to 2024.",
    image: "/kesseboehmer.jpg",
    link: "",
  },
  {
    title: "Sphinx Trap",
    subtitle: "Remote Trigger System for Bownet Traps",
    description:
      "A crossbow trigger mechanism, electrified and controlled by an ESP32-C3 with Long-Range WiFi. Features CNC-milled and cut mechanical parts for durability and precision.",
    image: "/sphinx.jpeg",
    link: "",
  },
  {
    title: "Bono",
    subtitle: "Employee Wellbeing App",
    description:
      "Developed a prototype Flutter app for iOS and Android. The sandbox app challenges users with physical, mental, and other wellness activities to encourage a healthy lifestyle.",
    image: "/Bono2.png",
    link: "",
  },
  {
    title: "MaiStudios",
    subtitle: "Modular Designer Furniture",
    description:
      "Worked on the design and CAD data creation for modular stainless steel designer furniture, featuring a splint plug-in system for assembly. Oversaw the production process. Learn more at www.maistudios.de",
    image: "/regal2.jpg",
    link: "",
  },
  {
    title: "Zeitgeist",
    subtitle: "A Smart Lamp to Teach Children the Concept of Time",
    description:
      "Prototyped a smart lamp designed to introduce children to the concept of time. Features controllable RGB LEDs, BLE connectivity, and a gyroscope sensor.",
    image: "/zeitgeist.jpg",
    link: "",
  },
  {
    title: "Birdcam",
    subtitle: "Long-term Monitoring System for Bird Nests with NLWKN",
    description:
      "An autonomous system powered by solar and battery, designed for outdoor use. Provides HD video monitoring for up to 6 weeks with a maintenance server for data management.",
    image: "/birdcam.gif",
    link: "",
  },
  {
    title: "Filz Asmussen",
    subtitle: "Industrial Length Measurement System",
    description:
      "A Raspberry Pi-based length measurement system featuring an encoder and a visual interface developed with QtPy for industrial use.",
    image: "/FALOGO.png",
    link: "",
  },
  {
    title: "SIModular",
    subtitle: "Modular Power Bank System",
    description:
      "Prototype development including CAD and circuit design for a modular power bank with a bayonet closure system for additional modules.",
    image: "/SiTem.jpeg",
    link: "",
  },
];

export const projectsDE = [
  {
    title: "Safetrap",
    subtitle: "Fallenmeldesystem für Jagd und Naturschutz",
    description:
      "Ein stromsparendes, langanhaltendes System, das einen AVR-Mikrocontroller mit GSM-Funktionalität nutzt. Enthält eine React-Web-App, die über eine Django-REST-API ferngesteuert und überwacht werden kann. Mehr erfahren unter www.safetrap.de.",
    image: "/fallenmelder_3.jpg",
    link: "",
  },
  {
    title: "Sphinx Trap",
    subtitle: "Fernsteuerungssystem für Netzfallen",
    description:
      "Ein Auslösemechanismus der für Netzfallenkonzipiert ist und durch einen ESP32-C3 mit Long-Range-WiFi gesteuert wird. Enthält CNC-gefräste und geschnittene mechanische Teile für Langlebigkeit und Präzision.",
    image: "/sphinx.jpeg",
    link: "",
  },
  {
    title: "Nordex",
    subtitle: "Ausbildung und Studium als Mechatroniker",
    description:
      "Praktische Erfahrungen in der Windkraftbranche, Arbeiten an großen Maschinen in Rostock von 2013 bis 2019.",
    image: "/nordex.jpg",
    link: "",
  },
  {
    title: "AgBRAIN & CCI",
    subtitle: "Datenmanagementsysteme in der Landtechnik",
    description:
      "Full-Stack-Entwickler für Datenmanagementsysteme in der Landtechnik. Entwicklung einer Vue-PWA mit einer C# .NET API und einer PostgreSQL-Datenbank. Projekt abgeschlossen im Jahr 2022.",
    image: "/cci.jpg",
    link: "",
  },
  {
    title: "Kesseböhmer",
    subtitle: "Produktentwickler",
    description:
      "Entwicklung elektronischer Produkte, von der Schaltungsentwicklung über das Gehäusemodellieren bis hin zum Zertifizierungsprozess, im Zeitraum von 2023 bis 2024.",
    image: "/kesseboehmer.jpg",
    link: "",
  },
  {
    title: "Bono",
    subtitle: "Mitarbeiter-Wellness-App",
    description:
      "Prototyp einer Flutter-App für iOS und Android. Die Sandbox-App stellt den Nutzern physische, mentale und andere Wellness-Herausforderungen, um einen gesunden Lebensstil zu fördern.",
    image: "/Bono2.png",
    link: "",
  },
  {
    title: "Sleek",
    subtitle: "Bluetooth-MIDI-Controller",
    description:
      "Ein BLE-MIDI-Controller, integriert in ein Gitarren-Schlagbrett, basierend auf einem NRF52-Mikrocontroller. Entwickelt als patentierte Lösung und preisgekrönter Prototyp im Jahr 2017.",
    image: "/showcase.png",
    link: "",
  },
  {
    title: "MaiStudios",
    subtitle: "Modulare Designer-Möbel",
    description:
      "Mitwirkung am Design und der Erstellung von CAD-Daten für modulare Edelstahl-Designermöbel, die mit einem Splint-Stecksystem zusammengebaut werden. Begleitung des Produktionsprozesses.",
    image: "/regal2.jpg",
    link: "",
  },
  {
    title: "Zeitgeist",
    subtitle: "Eine smarte Lampe, um Kindern das Konzept der Zeit näherzubringen",
    description:
      "Prototyp einer smarten Lampe, die Kindern das Konzept der Zeit näherbringt. Mit steuerbaren RGB-LEDs, BLE-Konnektivität und einem Gyrosensors ausgestattet.",
    image: "/showcase.png",
    link: "",
  },
  {
    title: "Virtual Estate",
    subtitle: "Auftragsbuchungssystem für Immobilienverwaltungen",
    description:
      "Entwicklung eines Web-Portals mit einer React-App und einer Python-Django-REST-API. Die Hardware umfasst einen QR-Scanner mit ESP32-S3 und Kamera, programmiert in C++.",
    image: "/ve.PNG",
    link: "",
  },
  {
    title: "Birdcam",
    subtitle: "Langzeitüberwachungssystem für Vogelgelege mit dem NLWKN",
    description:
      "Ein autarkes System, das mit Solar- und Batteriebetrieb im Freien anwendbar ist. Bietet HD-Videoüberwachung für bis zu 6 Wochen mit einem Wartungsserver zur Datenverwaltung.",
    image: "/showcase.png",
    link: "",
  },
  {
    title: "Filz Asmussen",
    subtitle: "Industrielles Längenmesssystem",
    description:
      "Ein auf Raspberry Pi basierendes Längenmesssystem mit einem Encoder und einer visuellen Schnittstelle, entwickelt auf Basis von QtPy für industrielle Anwendungen.",
    image: "/FALOGO.png",
    link: "",
  },
  {
    title: "SIModular",
    subtitle: "Modulares Powerbank-System",
    description:
      "Prototypentwicklung einschließlich CAD- und Schaltungsdesign für eine modulare Powerbank mit einem Bajonettverschluss für zusätzliche Module.",
    image: "/showcase.png",
    link: "",
  },
];

  export const skills = [
    { text: "C++", icon: CodeIcon },
    { text: "CAD (Creo & Fusion360)", icon: CubeIcon },
    { text: "Schaltungs- und Platinendesign", icon: ChipIcon },
    { text: "Python", icon: CodeIcon },
    { text: "3D Druck (FDM & SLA)", icon: CubeIcon },
    { text: "DART (Flutter APP)", icon: DeviceMobileIcon },
    { text: "JavaScript (REACT & Vue PWA)", icon: CodeIcon },
    { text: "CNC (Milling & Laser)", icon: CubeIcon },
    { text: "SQL (MariaDB, PostgreSQL)", icon: DatabaseIcon },
    { text: "C# (.NET)", icon: CodeIcon },
    { text: "Injection Molding", icon: PaperAirplaneIcon },
    { text: "Lean Startup Method", icon: AcademicCapIcon },
  ];