// src/components/Contact.js

import React from "react";

export default function Contact() {
  return (
    <section id="contact" className="bg-primaryGreen py-10 pt-navbar scroll-mt-[140px] lg:scroll-mt-140">
      <div className="container px-5 py-10 mx-auto text-center">
        <h2 className="text-softBeige sm:text-4xl text-3xl mb-4 font-semibold title-font">
          Contact Information
        </h2>
        <p className="leading-relaxed mb-8 text-softBeige">
          Get in touch with me via the contact details below.
        </p>
        <div className="bg-forestGreen rounded-lg p-8 inline-block">
          <div className="mb-4">
            <h3 className="title-font font-semibold text-softBeige tracking-widest text-xs">
              ADDRESS
            </h3>
            <p className="mt-1 text-softBeige">
              Niklas Läsche<br />
              EcoInnovate
            </p>
            <p className="mt-1 text-softBeige">
              Bgm.-Otto-Knapp-Str. 3 <br />
              49163 Bohmte, GERMANY
            </p>
          </div>
          <div className="mb-4">
            <h3 className="title-font font-semibold text-softBeige tracking-widest text-xs">
              EMAIL
            </h3>
            <a className="text-accentBlue leading-relaxed" href="mailto:niklas@laesche.de">
            niklas@laesche.de
            </a>
          </div>
          <div>
            <h3 className="title-font font-semibold text-softBeige tracking-widest text-xs">
              PHONE
            </h3>
            <p className="leading-relaxed text-softBeige">01573 / 1655 148</p>
          </div>
        </div>
      </div>
    </section>
  );
}
