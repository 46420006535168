// src/components/Projects.js

import { CodeIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import { projects, projectsDE } from "../data";

export default function Projects() {
  // State to track the screen size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update isMobile on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="projects" className="text-softBeige bg-forestGreen body-font pt-navbar scroll-mt-[140px] lg:scroll-mt-140">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4 text-accentBlue" />
          <h1 className="sm:text-4xl text-3xl font-semibold title-font mb-4 text-softBeige">
            Projects I've Built
          </h1>
          <p className="lg:w-3/4 mx-auto leading-relaxed text-base text-softBeige">Employing advanced technologies such as 3D printing, CNC machining, and laser cutting, I turn concepts into prototypes with speed and accuracy.
          I design production processes that are not only efficient but also minimize environmental impact, aligning with our vision for sustainability.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project, index) => (
            <div
              key={project.image}
              className="sm:w-1/3 w-full p-4"
            >
              <div
                className="group relative h-64 overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105"
                onClick={(e) => {
                  if (isMobile) {
                    const overlay = e.currentTarget.querySelector(".overlay");
                    overlay.style.opacity =
                      overlay.style.opacity === "1" ? "0" : "1";
                  }
                }}
              >
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src={project.image}
                />
                <div
                  className="absolute inset-0 flex flex-col justify-center items-center bg-primaryGreen bg-opacity-90 transition-opacity duration-300 overlay"
                  style={{ opacity: 0 }}
                  onMouseEnter={(e) => {
                    if (!isMobile) e.currentTarget.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    if (!isMobile) e.currentTarget.style.opacity = 0;
                  }}
                >
                  <h2 className="tracking-widest text-custom-sm title-font font-medium text-softBeige mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-semibold text-softBeige mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed font-semibold text-custom-s text-softBeige text-center">
                    {project.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
