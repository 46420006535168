// src/components/Skills.js

import {ChipIcon} from "@heroicons/react/solid";
import React from "react";
import { skills } from "../data";

export default function Skills() {
  return (
    <section id="skills" className="bg-softBeige py-10 pt-navbar scroll-mt-[140px] lg:scroll-mt-140">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <ChipIcon className="w-10 inline-block mb-4 text-primaryGreen" />
          <h1 className="sm:text-4xl text-3xl font-semibold title-font text-primaryGreen mb-4">
            Skills &amp; Technologies
          </h1>
          <p className="text-warmGray text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
          Utilizing cutting-edge technologies, the latest software, and modern frameworks, I bring precision and agility to the prototyping process. By applying lean startup methodologies in a hardware startup environment, I ensure a rapid, customer-focused development approach tailored to meet your product needs.
          </p>
        </div>
        <div className="flex flex-wrap px-5 py-10 sm:mx-auto sm:mb-2 -mx-2">
        {skills.map(({ text, icon: IconComponent }) => (
            <div key={text} className="p-2 sm:w-1/3 w-full">
              <div className="bg-primaryGreen rounded flex p-4 h-full items-center">
                <IconComponent className="text-accentBlue w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium text-softBeige">
                  {text}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
