// src/components/About.js

import React from "react";
import meImage from '../me.png';

export default function About() {
  return (
    <section id="about" className="bg-softBeige py-10 lg:py-0 scroll-mt-[140px] lg:scroll-mt-140">
      <div className="container mx-auto flex px-10 md:flex-row flex-col items-center">

      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-full mb-10 md:mb-0 order-first md:order-last flex justify-center">
          <img
            className="object-cover object-center rounded mx-auto w-full max-w-[512px] h-auto"
            alt="hero"
            src={meImage}
          />
        </div>
        {/* Text Section */}
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-semibold text-primaryGreen text-left">
            Hi, I'm Niklas.
            <br className="lg:inline-block " />Where Ideas Meet Impact.
          </h1>
          <p className="mb-8 leading-relaxed text-warmGray text-left">
            My expertise lies in developing mechanical and electrical products and prototypes, designing eco-conscious production processes, and navigating the path to market.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-softBeige bg-primaryGreen border-0 py-2 px-6 focus:outline-none hover:bg-forestGreen rounded text-lg">
              Work With Me
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-primaryGreen bg-softBeige border-0 py-2 px-6 focus:outline-none hover:bg-accentBlue hover:text-softBeige rounded text-lg">
              See My Past Work
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
