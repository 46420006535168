// src/components/Navbar.js

import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import logo from '../logo.PNG';

export default function Navbar() {
  return (
    <header className="bg-softBeige md:sticky top-0 z-10 font-sans">
      <div className="container mx-auto p-5 flex-wrap items-center md:flex hidden"> {/* Hidden on small screens */}
        <img src={logo} alt="Logo" className="w-15 h-10 mr-2" />
        
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-warmGray flex flex-wrap items-center text-base justify-center">
          <a href="#about" className="mr-5 text-primaryGreen hover:text-accentBlue no-underline text-lg font-bold">
            About Me
          </a>
          <a href="#projects" className="mr-5 text-primaryGreen hover:text-accentBlue no-underline text-lg font-bold">
            Projects
          </a>
          <a href="#skills" className="mr-5 text-primaryGreen hover:text-accentBlue no-underline text-lg font-bold">
            Skills
          </a>
        </nav>

        <a
          href="#contact"
          className="inline-flex items-center bg-primaryGreen border-0 py-2 px-4 focus:outline-none hover:bg-forestGreen rounded text-softBeige no-underline text-lg font-bold">
          Hire Me
          <ArrowRightIcon className="w-4 h-4 ml-1 text-softBeige" />
        </a>
      </div>
    </header>
  );
}
